/**
 * terminkalender.jsx
 *
 * @file Terminkalender page of the web application.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { GatsbySeo } from 'gatsby-plugin-next-seo'
import loadable from '@loadable/component'
import NProgress from 'nprogress'
import React from 'react'
import Typography from '@mui/material/Typography'

// loadable imports
const FullCalendarWrapper = loadable( () => import( '../components/FullCalendarWrapper/FullCalendarWrapper' ), { ssr: false } )

/**
 * This component represents the Terminkalender page of this application.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const TerminkalenderPage = () => {
	NProgress.start()

	return (
		<>
			<GatsbySeo title="Terminkalender" />
			<Typography align="center" color="primary" gutterBottom variant="h4">Terminkalender</Typography>
			<FullCalendarWrapper />
		</>
	)
}

export default TerminkalenderPage
